import React, { useEffect, useState } from 'react'
import { css } from '@emotion/core'
import { useFormik } from 'formik'
import { object, string, boolean, ref } from 'yup'
import { colors, spacer } from '@dqp/common/styles/utilities'
import Button from '@dqp/common/components/Button/Button'
import Text, { ErrorText } from '@dqp/common/components/Text/Text'
import { ExternalLink } from '@dqp/common/components/Link/Link'
import TextField from '@dqp/common/components/TextField/TextField'
import PasswordField from '@dqp/common/components/PasswordField/PasswordField'
import Checkbox from '@dqp/common/components/Checkbox/Checkbox'
import Cookies from 'js-cookie'
import routes from '#globals/routes'
import { useRegister } from '#api/auth'
import parseValidationErrors from '#utils/parseValidationErrors'
import { storageKeys } from '#globals/constants'
import getErrorMessage from '#utils/getErrorMessage'

const SignupSchema = object().shape({
  first_name: string().required('First Name is required.'),
  last_name: string().required('Last Name is required.'),
  email: string()
    .email('Invalid email.')
    .required('Email is required.'),
  password: string()
    .min(8, 'Password is too short - should be 8 chars minimum.')
    .required('Password is required.'),
  password_confirmation: string()
    .required('Please confirm your password.')
    .oneOf([ref('password'), null], "Passwords don't match"),
  termsAndCondition: boolean()
    .oneOf([true], 'Please check this box to proceed.')
    .required(),
})

function SignupForm() {
  const { register } = useRegister()
  const [errorMessage, setErrorMessage] = useState('')
  const {
    handleChange,
    handleSubmit,
    handleBlur,
    values,
    errors: formErrors,
    isValid,
    touched,
    isSubmitting,
  } = useFormik({
    validateOnBlur: true,
    validationSchema: SignupSchema,
    initialValues: {
      first_name: ``,
      last_name: ``,
      email: ``,
      password: ``,
      password_confirmation: ``,
      termsAndCondition: false,
    },

    onSubmit: async (formData, actions) => {
      try {
        await register({
          variables: formData,
        })
      } catch (error) {
        const validationErrors = parseValidationErrors(error)
        const message = getErrorMessage(error)
        if (validationErrors) actions.setErrors(validationErrors)
        else if (message) setErrorMessage(message)
      }
    },
  })
  useEffect(() => {
    Cookies.set(storageKeys.keepLoggedIn, false, {
      expires: 365,
    })
  }, [])
  useEffect(() => {
    setErrorMessage('')
  }, [values])

  return (
    <form onSubmit={handleSubmit}>
      <div className='container-fluid no-push' css={[spacer.padLR0]}>
        <div className='row'>
          <TextField
            className='col-sm-6'
            name='first_name'
            label='First Name'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.first_name}
            error={touched.first_name && formErrors.first_name}
            css={spacer.mrB20}
          />
          <TextField
            className='col-sm-6'
            name='last_name'
            label='Last Name'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.last_name}
            error={touched.last_name && formErrors.last_name}
            css={spacer.mrB20}
          />
        </div>
      </div>
      <TextField
        name='email'
        label='Email'
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.email}
        error={touched.email && formErrors.email}
        css={spacer.mrB20}
      />
      <PasswordField
        name='password'
        label='Password'
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.password}
        error={touched.password && formErrors.password}
        css={spacer.mrB20}
      />
      <PasswordField
        name='password_confirmation'
        label='Confirm Password'
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.password_confirmation}
        error={
          touched.password_confirmation &&
          formErrors.password_confirmation
        }
        css={spacer.mrB20}
      />
      <div css={spacer.mrB30}>
        <div>
          <Text
            as='label'
            size='small'
            color={colors.black}
            className='d-flex align-items-center flex-wrap'
          >
            <Checkbox
              name='termsAndCondition'
              onChange={handleChange}
              onClick={handleBlur}
              checked={values.termsAndCondition}
              css={css`
                ${spacer.mrR10};
                border-color: ${touched.termsAndCondition &&
                formErrors.termsAndCondition
                  ? colors.darkRed
                  : colors.black};
                fill: ${colors.black};
                background-color: white;
              `}
            />{' '}
            I accept Daily Quest Plus’&nbsp;
            <strong>
              <ExternalLink
                target='_blank'
                to={routes.terms.to}
                borderColor={colors.black}
              >
                Terms
              </ExternalLink>
            </strong>
            &nbsp;and&nbsp;
            <strong>
              <ExternalLink
                target='_blank'
                to={routes.privacy.to}
                borderColor={colors.black}
              >
                Privacy Policy
              </ExternalLink>
            </strong>
          </Text>
        </div>
        {touched.termsAndCondition &&
          formErrors.termsAndCondition && (
            <ErrorText>{formErrors.termsAndCondition}</ErrorText>
          )}
      </div>
      {errorMessage && (
        <ErrorText css={spacer.mrB20}>{errorMessage}</ErrorText>
      )}
      <Button
        size='medium'
        variant='black'
        type='submit'
        disabled={!isValid || isSubmitting}
        isLoading={isSubmitting}
      >
        SIGN UP
      </Button>
    </form>
  )
}

export default SignupForm
