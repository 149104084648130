import React from 'react'
import { css } from '@emotion/core'
import { Fade } from 'react-reveal'
import {
  colors,
  breakpoints,
  spacer,
} from '@dqp/common/styles/utilities'
import Title from '@dqp/common/components/Title/Title'
import Text from '@dqp/common/components/Text/Text'
import Image from '@dqp/common/components/Image'
import Link from '@dqp/common/components/Link/Link'
import SignupForm from './SignupForm'
import {
  reactRevealDistance,
  reactRevealDuration,
} from '#globals/constants'
import routes from '#globals/routes'

const styles = {
  contaner: css`
    background-color: ${colors.yellow};
    ${breakpoints({
      paddingTop: [40, 40, 90, 110, 110],
      paddingBottom: [80, 80, 90, 110, 110],
    })};
  `,
  title: css`
    ${spacer.mrB20};
  `,
  text: css`
    line-height: 1.5;
    ${spacer.mrB30};
  `,
  star: css`
    ${breakpoints({
      top: [0, 0, 0, -370, -370],
      right: [0, 0, 0, -170, -170],
    })};
  `,
}
function Hero() {
  return (
    <section css={styles.contaner}>
      <div className='container'>
        <div className='row align-items-center'>
          <Fade
            left
            distance={reactRevealDistance}
            duration={reactRevealDuration}
          >
            <div className='col-lg-5 d-lg-flex d-none justify-content-center'>
              <Image
                css={styles.star}
                className='d-none d-lg-block'
                alt='star '
                image='signup/star'
                sizes={{
                  lg: {
                    width: 788,
                  },
                  md: {},
                }}
              />
            </div>
          </Fade>
          <div className='offset-lg-1 col-lg-6'>
            <Title
              as='h1'
              size='xxxxLarge'
              weight='bold'
              color={colors.black}
              css={styles.title}
            >
              Get started with a free account
            </Title>
            <Text size='large' color={colors.black} css={styles.text}>
              No credit card required. Free questions and tutorials.
              Already have an account?{' '}
              <strong>
                <Link to={routes.login.to} borderColor={colors.black}>
                  Log In
                </Link>
              </strong>
            </Text>
            <SignupForm />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Hero
