import React from 'react'
import { colors } from '@dqp/common/styles/utilities'
import Wave from '@dqp/common/components/Wave/Wave'
import Hero from './Hero'

function SignUp() {
  return (
    <>
      <Hero />
      <Wave bg={colors.yellow} fill={colors.white} type='footer' />
    </>
  )
}

export default SignUp
